export const customStyles = {
    control: (base, state) => ({
        ...base,
        borderColor: "black",
        borderRadius: 0,
        boxShadow: "black",
        fontFamily: '"Times New Roman", Times, serif',
        fontSize: "21px",

        "&:hover": { borderColor: "black", cursor: 'pointer'},
        "&:active": { boxShadow: "none" },
        "&:focus": { boxShadow: "none" },
    }),
    option: (base, state) => ({
        ...base,
        fontFamily: '"Times New Roman", Times, serif',
        fontSize: "18px",
    }),
    singleValue: (base, state) => ({
        ...base,
        fontFamily: '"Times New Roman", Times, serif',
    }),
};
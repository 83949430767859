import React from "react";
import "./selfprofile.css";

const SinInput = (props) => {
	return (
		<div className="profile-input">
			{props.name}
			<input
				type="text"
				className="single-input-box"
				defaultValue={props.value ? props.value : ""}
				onChange={props.onChange}
			/>
		</div>
	);
};

export default SinInput;

import React from "react";
import "./selfprofile.css";
import { imageUrlFor } from "../../lib/image-url";
import { navigate } from "gatsby";
import emptyMainImg from "../icon-and-img/empty.ico"

const Project = (props) => {
	if(props.data.mainImage){
		var imageURL = imageUrlFor(props.data.mainImage.asset._ref).url();
	}else{
		console.log("No image here.")
		// Default image
		var imageURL = emptyMainImg
	}
	

	function handleClick() {
		const projectId = props.data._id;
		if (typeof window !== `undefined`) {
			window.localStorage.setItem("projectId", projectId);
		}
		// const storedValue = localStorage.getItem("projectId");
		// console.log(storedValue);
		navigate("/editPage");
	}

	return (
		<div style={{ height: "auto", padding: "22px" }}>
			<img
				style={{ maxWidth: "100%", width: "100%", cursor: "pointer" }}
				src={imageURL}
				onClick={handleClick}
			/>
			<h1 className="projects-h2">
				{" "}
				{props.data.title} {props.institution},{" "}
				{props.data.publishedAt.substring(0, 4)}
			</h1>
		</div>
	);
};

export default Project;

import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import ProfilePreviewGrid from "../components/profile-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Login from '../components/login/login-component.jsx'
import SelfProfile from "../components/self-profile/SelfProfile.jsx";


const ProfilePage = () => {
	

	return (
		<div>
			{/* When add a new page, the new content must be inside the Layout content. Otherwise the new content it will not clickable.*/}
			<Layout>
				<SelfProfile/>
			</Layout>
		</div>
		
	);
};

export default ProfilePage;
const PROJECT_ID = "ugztar6v";
const DATASET = "production";

export const updateBlockData = (theName, theId, theData) => {
	const wrappedData = [
		{
			_key: "2cf61f780603",
			_type: "block",
			children: [
				{
					_key: "da9dc50335a00",
					_type: "span",
					text: theData,
				},
			],
			markDefs: []
		},
	];

	const mutations = [
		{
			patch: {
				id: theId,
				set: {
					[theName]: wrappedData,
				},
			},
		},
	];

	//Get this token from the sanity backend.
	const tokenWithWriteAccess =
		"skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV";

	fetch(
		`https://${PROJECT_ID}.api.sanity.io/v2021-06-07/data/mutate/${DATASET}`,
		{
			method: "post",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${tokenWithWriteAccess}`,
			},
			// The data need json format.
			body: JSON.stringify({ mutations }),
		}
	)
		.then((response) => response.json())
		.then((result) => console.log(result))
		.catch((error) => console.error(error));
};

/** @format */

import React from "react";
import "./selfprofile.css";
import SinInput from "./SinInput";
import Button from "./Button";
import { useState } from "react";
import ProjectList from "./ProjectList";
import { useEffect } from "react";
import { updateBlockData } from "../updateBlockDate";
import { navigate } from "gatsby";
import { fetchUserProjects } from "./fetchUserProjects";
import Select from "react-select";
import { customStyles } from "./selectStyle";
import { updateSelectData } from "./updateSelectData";
import { updateKeywords } from "./updateKeywords";
import { updateSocials } from "./updateSocials";
import { updateSlug } from "./updateSlug";
import { client } from "../../containers/client.js";
import { generateSlug } from "../edit/generateSlug";
import { checkSlug } from "./checkSlug";
import Toast from "../utilities/Toast";

const SelfProfile = () => {
	///////////////////////////////////////////////////////////////////////////
	////////////////// State Section  /////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////
	const [userInfo, setUserInfo] = useState({});
	const [loadingFinished, setLoadingFinished] = useState(false);
	const [bio, setBio] = useState("");
	// select program options
	const [programOptions, setProgramOptions] = useState();
	const [selectedProgramOption, setSelectedProgramOption] = useState(null);

	// select institution options
	const [institutionOptions, setInstitutionOptions] = useState();
	const [selectedInstitutionOption, setSelectedInstitutionOption] = useState(
		null
	);

	// select Discipline options
	const [disciplineOptions, setDisciplineOptions] = useState();
	const [selectedDisciplineOption, setSelectedDisciplineOption] = useState(
		null
	);

	// keywords state
	const [keywords, setKeywords] = useState("");

	// social website state
	const [socialWebsite, setSocialWebsite] = useState("");

	// social ins state
	const [socialIns, setSocialIns] = useState("");

	// socialTwitter state
	const [socialTwitter, setSocialTwitter] = useState("");

	// info update finished
	const [infoUpdated, setInfoUpdated] = useState(false);

	// social update finished
	const [socialUpdated, setSocialUpdated] = useState(false);

	// slug
	const [userSlug, setUserSlug] = useState("");

	///////////////////////////////////////////////////////////////////////////
	////////////////// Function Section  //////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////

	const onLogout = () => {
		if (typeof window !== `undefined`) {
			window.localStorage.removeItem("email");
			navigate("/login", { replace: true });
		}
	};

	const splitKeywords = (rowKeywords) => {
		const tagArray = rowKeywords.split(",");
		const tagObject = tagArray.map((tag) => ({
			value: tag.trim(),
			label: tag.trim(),
		}));
		return tagObject;
	};

	const keyWordsOnchange = (event) => {
		const rowKeywords = event.target.value;
		// console.log(rowKeywords);
		let arrKeywords = splitKeywords(rowKeywords);
		setKeywords(arrKeywords);
		// console.log(keywords);
	};

	// top save button function
	const infoUpload = async () => {
		alert("Save button clicked!");
		// console.log(bio);
		// update bio info
		if (bio) {
			updateBlockData("bio", userInfo._id, bio);
		}

		// update user slug
		// console.log(userSlug);

		let newSlug = userSlug;
		// 1. check user slug is null?
		if (!userSlug) {
			newSlug = generateSlug(userInfo.name);
			setUserSlug(newSlug);
		}

		// 2. check user slug exist?
		let slugOK = await checkSlug(userInfo._id, newSlug);

		// 3. if already exist add "-**" after slug
		if (!slugOK) {
			newSlug = newSlug + "-" + Math.round(Math.random() * 100);
			setUserSlug(newSlug);
		}
		// 3. updateSlug
		updateSlug(userInfo._id, newSlug);
		setUserSlug(newSlug);

		// update program info
		updateSelectData("program", userInfo._id, selectedProgramOption);

		// update institution
		updateSelectData(
			"institution",
			userInfo._id,
			selectedInstitutionOption
		);

		// update place of practice
		updateSelectData("discipline", userInfo._id, selectedDisciplineOption);

		// update keywords
		updateKeywords(userInfo._id, keywords, setInfoUpdated);
		// console.log(infoUpdated);
		return true;
	};

	const onSave = async () => {
		await infoUpload().then((result) => {
			setInfoUpdated(result);
			alert("Data synchronization is complete, please refresh the page");
		});
	};

	// update if the upload finished

	const socialUpload = async () => {
		updateSocials(userInfo._id, socialWebsite, socialIns, socialTwitter);
	};

	const socialOnSave = async () => {
		await socialUpload().then((result) => {
			setSocialUpdated(true);
			alert("Data synchronization is complete, please refresh the page");
		});
	};

	const socialWebsiteOnChange = (event) => {
		setSocialWebsite(event.target.value);
	};

	const socialInsOnChange = (event) => {
		setSocialIns(event.target.value);
	};
	const userSlugOnChange = (event) => {
		setUserSlug(event.target.value);
	};

	const socialTwitterOnChange = (event) => {
		setSocialTwitter(event.target.value);
	};

	// log socialWebsite when it has been changed
	// useEffect(() => {
	// 	console.log(socialWebsite);
	// }, [socialWebsite]);

	///////////////////////////////////////////////////////////////////////////
	////////////////// Data Fetching  /////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////

	// get user email from local storage
	if (typeof window !== `undefined`) {
		var USEREMAIL = window.localStorage.getItem("email");
	}
	// Set query URL
	const PROJECT_ID = "ugztar6v";
	const DATASET = "production";
	// query person table based on email address
	const QUERY_USERINFO = encodeURIComponent(
		`*[_type == "person" && email == "${USEREMAIL}"]{
			_id,
			bio,
			name,
			email,
			slug {
				current
			},
			program -> {
				_id,
				name
			},
			institution -> {
				_id,
				name
			},
			discipline -> {
				_id,
				name
			},
			keywords,
			twitter,
			instagram,
			personalWebsite,
			"projects": *[ _type == "project" && members[0].person._ref == ^._id ]{
				_id,
				title,
				mainImage,
				otherImages,
				publishedAt,
				slug
			},
			"programs": *[ _type == "program"],
			"institutions": *[ _type == "institution"]{
				_id,
				name,
			},
			"disciplines": *[ _type == "discipline"]{
				_id,
				name,
			},
		}`
	);
	// Combine to one query URL
	let URL_USERINFO = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY_USERINFO}`;

	useEffect(() => {
		if (!USEREMAIL) {
			alert("You need to login");
			navigate("/login", { replace: true });
		}
	}, []);

	// fetch userInfo based on userEmail
	useEffect(() => {
		async function fetchUserInfo() {
			try {
				const res = await fetch(URL_USERINFO);
				if (res.status === 200) {
					const data = await res.json();
					const result = await data.result;
					// console.log(result);
					setUserInfo(result[0]);
					// Set Bio
					if (result[0].bio) {
						setBio(result[0].bio[0].children[0].text);
					}

					// set slug
					if (result[0].slug) {
						setUserSlug(result[0].slug.current);
					}

					// set program options
					setProgramOptions(
						result[0].programs.map((option) => ({
							value: option._id,
							label: option.name,
						}))
					);
					{
						result[0].program
							? setSelectedProgramOption({
									value: result[0].program._id,
									label: result[0].program.name,
							  })
							: setSelectedProgramOption({
									value: "",
									label: "",
							  });
					}

					// set Institution options
					setInstitutionOptions(
						result[0].institutions.map((option) => ({
							value: option._id,
							label: option.name,
						}))
					);
					// set selected institution

					{
						result[0].institution
							? setSelectedInstitutionOption({
									value: result[0].institution._id,
									label: result[0].institution.name,
							  })
							: setSelectedInstitutionOption({
									value: "",
									label: "",
							  });
					}

					// set Discipline options
					setDisciplineOptions(
						result[0].disciplines.map((option) => ({
							value: option._id,
							label: option.name,
						}))
					);
					{
						result[0].discipline
							? setSelectedDisciplineOption({
									value: result[0].discipline._id,
									label: result[0].discipline.name,
							  })
							: setSelectedDisciplineOption({
									value: "",
									label: "",
							  });
					}

					// set keywords
					{
						result[0].keywords
							? setKeywords(result[0].keywords)
							: setKeywords("");
					}

					// set social website
					{
						result[0].personalWebsite
							? setSocialWebsite(result[0].personalWebsite)
							: setSocialWebsite("");
					}

					// set ins
					{
						result[0].instagram
							? setSocialIns(result[0].instagram)
							: setSocialIns("");
					}

					// setSocialTwitter
					{
						result[0].twitter
							? setSocialTwitter(result[0].twitter)
							: setSocialTwitter("");
					}

					setLoadingFinished(true);
				} else {
					alert("Error", res.status);
				}
			} catch (err) {
				console.log(err);
			} finally {
				// fetchUserProjects(userInfo._id);

				setInfoUpdated(false);
				setSocialUpdated(false);
				// console.log(infoUpdated);
				// console.log(socialUpdated);
				// console.log("info fetch finished");
				// console.log(userInfo.keywords);
			}
		}
		fetchUserInfo();
	}, []);

	///////////////////////////////////////////////////////////////////////////
	////////////////// Create new project  ////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////
	const createNewProject = () => {
		const doc = {
			_type: "project",
			// default time.
			publishedAt: "2021-01-01T13:00:00.000Z",
			members: [
				{
					_type: "projectMember",
					_key: "4039c637b09c",
					person: { _ref: userInfo._id },
				},
			],
		};

		client
			.create(doc)
			.then((res) => {
				console.log(`A project was created, document ID is ${res._id}`);
				localStorage.setItem("projectId", res._id);
			})
			.then(() => {
				localStorage.setItem("new", 1);
				navigate("/editPage");
			});
	};

	///////////////////////////////////////////////////////////////////////////
	////////////////// UI components  /////////////////////////////////////////
	///////////////////////////////////////////////////////////////////////////
	return (
		<div className="page-wrapper">
			<Toast />

			<div className="page-left">
				<h1 className="profile-h1">
					{loadingFinished ? userInfo.name : "Loading..."}
				</h1>

				{userInfo.slug ? (
					<p
						className="preview"
						onClick={() => {
							navigate("/profile/" + userInfo.slug.current);
						}}
					>
						Preview
					</p>
				) : (
					""
				)}

				<div
					style={{
						maxWidth: "700px",
						marginRight: "auto",
						marginLeft: "auto",
					}}
				>
					<SinInput
						name="Slug (URL)"
						value={userSlug}
						onChange={userSlugOnChange}
					/>

					<div className="profile-input">
						Bio
						<textarea
							type="text"
							style={{
								height: "200px",
								padding: "6px",
								paddingLeft: "10px",
							}}
							className="single-input-box"
							defaultValue={bio}
							onChange={(e) => setBio(e.target.value)}
						/>
					</div>
					{/* <SinInput
						name="Program"
						value={loadingFinished ? userInfo.program.name : ""}
					/> */}
					<p className="select-heading">Program</p>

					{loadingFinished ? (
						<Select
							// className="profile-select"
							value={selectedProgramOption}
							onChange={(selectedProgramOption) => {
								setSelectedProgramOption(selectedProgramOption);
								// console.log(selectedProgramOption);
							}}
							styles={customStyles}
							options={programOptions}
						/>
					) : (
						""
					)}

					{/* <SinInput
						name="Institution or Affiliation"
						value={loadingFinished ? userInfo.institution.name : ""}
					/> */}

					<p className="select-heading">Institution or Affiliation</p>
					{loadingFinished ? (
						<Select
							// className="profile-select"
							value={selectedInstitutionOption}
							onChange={(selectedInstitutionOption) => {
								setSelectedInstitutionOption(
									selectedInstitutionOption
								);
								// console.log(selectedInstitutionOption);
							}}
							styles={customStyles}
							options={institutionOptions}
						/>
					) : (
						""
					)}
					{/* <SinInput
						name="Place of Practice"
						value={loadingFinished ? userInfo.discipline.name : ""}
					/> */}

					<p className="select-heading">Discipline</p>
					{loadingFinished ? (
						<Select
							// className="profile-select"
							value={selectedDisciplineOption}
							onChange={(selectedDisciplineOption) => {
								setSelectedDisciplineOption(
									selectedDisciplineOption
								);
								// console.log(selectedDisciplineOption);
							}}
							styles={customStyles}
							options={disciplineOptions}
						/>
					) : (
						""
					)}
					<SinInput
						name="Keywords"
						value={
							keywords !== ""
								? keywords.map((item, index) => {
										const result = item.value.trim();
										if (index == 0) {
											return result;
										} else {
											return " " + result;
										}
								  })
								: ""
						}
						onChange={keyWordsOnchange}
					/>
					{infoUpdated ? (
						<Button name="Saved" onClick={onSave} float="right" />
					) : (
						<Button name="Save" onClick={onSave} float="right" />
					)}
				</div>

				<div
					style={{
						maxWidth: "700px",
						marginRight: "auto",
						marginLeft: "auto",
					}}
				>
					<h2 style={{ marginTop: "60px" }} className="profile-input">
						Social Media Links:
					</h2>
					<SinInput
						name="Website"
						value={loadingFinished ? socialWebsite : ""}
						onChange={socialWebsiteOnChange}
					/>
					<SinInput
						name="Instagram"
						value={loadingFinished ? socialIns : ""}
						onChange={socialInsOnChange}
					/>
					<SinInput
						name="Twitter"
						value={loadingFinished ? socialTwitter : ""}
						onChange={socialTwitterOnChange}
					/>
					{/* <SinInput
						name="Email"
						value={loadingFinished ? userInfo.email : ""}
					/> */}

					{socialUpdated ? (
						<Button
							name="Saved"
							float="right"
							onClick={socialOnSave}
						/>
					) : (
						<Button
							name="Save"
							float="right"
							onClick={socialOnSave}
						/>
					)}

					{/* <Button name="Logout" onClick={onLogout} float="left" /> */}

					<div style={{ height: "50px" }}></div>
				</div>
			</div>

			<div className="page-middle"></div>
			<div style={{ textAlign: "center" }} className="page-left">
				<h1 className="projects-h1"> List of Projects </h1>

				{userInfo.projects && userInfo.projects.length !== 0 ? (
					<ProjectList
						projectData={userInfo.projects}
						institution={
							userInfo.institution
								? userInfo.institution.name
								: ""
						}
					/>
				) : (
					""
				)}

				<button
					className="save-btn"
					onClick={() => {
						createNewProject();
					}}
					style={{ cursor: "pointer" }}
				>
					Add a project
				</button>
				<div style={{ height: "50px" }}></div>
			</div>
		</div>
	);
};

export default SelfProfile;

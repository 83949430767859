import React from "react";
import './selfprofile.css'

const Button = (props) => {
	return (
		<button style={{float: props.float}} className="save-btn" onClick={props.onClick}>
            {props.name}
		</button>
	);
};

export default Button;

/** @format */
// if slug exists, this function will return false, if slug ok, this function will return true

export const checkSlug = async (objectId, currentSlug) => {
	const PROJECT_ID = "ugztar6v";
	const DATASET = "production";
	const QUERY = encodeURIComponent(`*[_type == "person"]`);
	let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

	try {
		const res = await fetch(URL);
		if (res.status === 200) {
			const data = await res.json();
			const result = await data.result;
			// console.log(result);
			// console.log(result[0]._id);
			// console.log(result[0].slug.current);
			for (let i = 0; i < result.length; i++) {
				if (
					result[i].slug &&
					result[i].slug.current === currentSlug &&
					result[i]._id !== objectId
				) {
					// sulg exist
					console.log("slug exist");
					return false;
				}
			}
			console.log("slug OK");
			return true;
		} else {
			alert("Error", res.status);
		}
	} catch (err) {
		console.log(err);
	} finally {
		console.log(``);
	}
};

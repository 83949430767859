const PROJECT_ID = "ugztar6v";
const DATASET = "production";
const tokenWithWriteAccess =
	"skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV";

export function updateKeywords(theId, theContent, setInfoUpdated) {
	const mutations = [
		{
			patch: {
				id: theId,
				set: {
					keywords: theContent,
				},
			},
		},
	];

	fetch(
		`https://${PROJECT_ID}.api.sanity.io/v2021-06-07/data/mutate/${DATASET}`,
		{
			method: "POST",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${tokenWithWriteAccess}`,
			},
			// The data need json format.
			body: JSON.stringify({ mutations }),
		}
	)
		.then((response) => response.json())
		.then((result) => {
			console.log(result);
			setInfoUpdated(true);
		})
		.catch((error) => console.error(error));
}

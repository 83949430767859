import React from "react";
import Project from "./Project";

const ProjectList = (props) => {
	
	// console.log(props.projectData);
	const projectData = props.projectData;
	// console.log("from project list");
	// console.log(projectData);
	// console.log(typeof projectData);
	// console.log(projectData[0].mainImage.asset._ref);
	// projectData.map((data) => {
	// 	console.log(data);
	// });

	return (
		<div
			style={{
				marginLeft: "10%",
				width: "80%",
				display: "grid",
				gridTemplateColumns: "50% 50%",
				paddingTop: "40px",
			}}
		>
			{projectData.map((data) => (
				<Project data={data} institution={props.institution} />
			))}
		</div>
	);
};

export default ProjectList;
